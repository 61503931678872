.email_confirmation {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    height: 100vh;
}

.check-icon {
background-color: #39E600;
padding: 14px;
color: white;
border-radius: 50%;
margin: 0;
height: 50px;
width: 50px;
margin-right: auto;
margin-left: auto;
}

.email_confirmation .ayo_logo {
    height: auto;
    width: 150px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
}