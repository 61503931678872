.reset-password form {
    max-width: 300px;
    margin: 0 auto;
    padding: 0;
    border: none;
    background-color: transparent;
}

.reset-password .form {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: center;
    justify-content: start;
}

.reset-password .row-form {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.hide-span {
margin-left: 8px;
}

.reset-password .form h1 {
    color: white;
}

.reset-password .form .error {
    margin-top: 12px;
    color: red;
}
.reset-password .form input {
    padding-top: 12px;
    padding-bottom: 12px;
    border: none;
    border-radius: 8px;
}

.reset-password .form .submit-btn {
    padding-top: 12px;
    padding-bottom: 12px;
    border: none;
    font-size: 18px;
    font-weight: 600;
    border-radius: 8px;
}

.reset-password {
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: center;
    justify-content: start;
    height: 100vh;
}

.check-icon {
    background-color: #39E600;
    padding: 14px;
    color: white;
    border-radius: 50%;
    margin: 0;
    height: 50px;
    width: 50px;
    margin-right: auto;
    margin-left: auto;
}

.reset-password .ayo_logo {
    height: auto;
    width: 150px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
}