.delete_account {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    height: 100vh;
}

.check-icon {
background-color: #39E600;
padding: 14px;
color: white;
border-radius: 50%;
margin: 0;
height: 50px;
width: 50px;
margin-right: auto;
margin-left: auto;
}

.delete_account .ayo_logo {
    height: auto;
    width: 150px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
}

/* Style du formulaire */
form {
    width: 500px;
    margin: 0 auto;
    padding: 30px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  /* Style des étiquettes */
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  /* Style des champs de saisie */
  input[type="text"],
  input[type="password"] {
    width: 100%;
    padding: 8px;
    margin-bottom: 18px;
    border: 1px solid #ccc;
    border-radius: 3px;
    box-sizing: border-box; /* pour inclure le padding et le border dans la largeur totale */
  }
  
  /* Style du bouton de soumission */
  button[type="submit"] {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 3px;
    background-color: #007bff; /* couleur de fond */
    color: #fff; /* couleur du texte */
    cursor: pointer;
  }
  
  /* Style du bouton de soumission au survol */
  button[type="submit"]:hover {
    background-color: #0056b3; /* couleur de fond au survol */
  }