.ayo_logo {
    height: auto;
    width: 350px;
    margin-left: auto;
    margin-right: auto;
}

.store_button {
    display: flex;
    flex-direction: row;
    border-radius: 16px;
    padding: 8px 18px;
    background-color: black;
    align-content: center;
    justify-content: center;
    color: white;
    margin-right: 14px;
    margin-left: 14px;
    -webkit-box-shadow: 1px 1px 3px 0 rgba(0,0,0,0.65);
    box-shadow: 1px 1px 3px 0 rgba(0,0,0,0.40);
    transition: 0.5s;
    cursor: pointer;
}

.store_button:hover {
    background-color: rgba(83, 104, 120, 0.6);
}

.store_button div {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
}

.store_button .download_text {
    font-size: 16px;
}

.store_button .store_name {
    font-size: 22px;
    font-weight: 600;
}

.store_button .store_icon {
    margin-right: 10px;
}

.social_icon_container {
    position: absolute;
    bottom: 0;
    width: 100vw;
    justify-content: center;
    padding: 10px;
    display: flex;
    flex-direction: row;
}

.social_icon {
    margin-right: 12px;
    margin-left: 12px;
    color: white;
    cursor: pointer;
}

.social_icon > * {
    height: 24px;
}