.home {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    height: 100vh;
}

.typing_text {
    font-size: 3em;
    font-weight: 800;
    color: white;
    text-align: center;
    padding: 35px;
    letter-spacing: 1.5px;
}

.store_button_container {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
}

@media only screen and (max-width : 768px) {
    .store_button_container {
        flex-direction: column;
    }
    .store_button_container .store_button {
        margin-bottom: 24px;
    }
}